<template>
  <div>
    <sidebar-verifikasi-probono-cl-e />
    <b-card no-body class="text-center mt-1">
      <template #header>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <h4 class="mb-0">
                Daftar Layanan
              </h4>
            </b-col>
            <b-col cols="4" class="text-right">
              <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="openForm()"
              >
                Tambah Layanan Baru
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body class="text-left mt-1 ml-2 mr-2 mb-2">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Klien:</label>
              <b-form-input
                v-model="selectedKlien"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Layanan:</label>
              <b-form-input
                v-model="selectedLayanan"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-end">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="selectedPerPage"
              :options="optionsPerPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterSelected"
            />
            <label>(Found {{ pagination.totalRows }} records)</label>
          </b-col>
          <b-col md="4">
            <label>Sort by</label>
            <v-select
              v-model="selectedSortBy"
              label="nama"
              :options="optionsSortBy"
              :clearable="false"
              @input="filterSelected"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              :items="daftarUser"
              :fields="fields"
              :busy="isBusy"
              class="mt-2"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(id)="data">
                {{ data.value }}
              </template>
              <template #cell(deskripsi)="{item}">
                {{ item.deskripsi }}
              </template>
              <template #cell(jumlah_waktu)="{item}">
                {{ item.jumlah_waktu }} Jam
              </template>
              <template #cell(is_lampiran)="data">
                <b-form-checkbox
                  v-if="data.item.is_lampiran"
                  value="1"
                  checked="1"
                  disabled
                >
                  Tersedia, sebutkan:
                </b-form-checkbox>
                <b-form-checkbox v-else disabled>
                  Tidak Tersedia
                </b-form-checkbox>
                <li v-for="item in data.item.probono_lampiran" :key="item.id">
                  <small
                    @click="download(data.item.probono_lampiran[0].nama_file)"
                    ><a ref>{{ item.nama_file }}</a></small
                  >
                </li>
              </template>
              <template #cell(status_verifikasi)="{item}">
                <b-badge pill variant="primary" v-if="!item.verification_status"
                  >Belum Verifikasi</b-badge
                >
                <b-badge
                  pill
                  variant="success"
                  v-else-if="item.verification_status == 1"
                  >Terverifikasi</b-badge
                >
                <b-badge pill variant="danger" v-else>Gagal Verifikasi</b-badge>
                <p v-if="item.verification_notes">
                  Notes: {{ item.verification_notes }}
                </p>
              </template>
              <template #cell(action)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  size="sm"
                  @click="confirmDelete(data.index)"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                  :to="{
                    name: 'probono-layanan-form',
                    params: { id: data.item.id, aksi: 'ubah' },
                  }"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-if="!data.item.verification_status && role == 'admin'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                  @click="verifikasi(data.item.id)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination-nav
              v-if="showPagination"
              v-model="selectedPageNum"
              :link-gen="linkGen"
              :number-of-pages="pagination.numberOfPages"
              use-router
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import {
  BCard,
  VBModal,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BContainer,
  BTable,
  // BLink,
  BPaginationNav,
  BSpinner,
  BFormCheckbox,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import moment from 'moment'
import { api, download } from '@/hap-sia/setup'
// import { forEach } from 'postcss-rtl/lib/affected-props'
// import { forEach } from 'postcss-rtl/lib/affected-props'
import SidebarVerifikasiProbonoClE from '../hap/shared/SidebarVerifikasiProbonoCLE.vue'

export default {
  components: {
    SidebarVerifikasiProbonoClE,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BContainer,
    BTable,
    // BLink,
    BPaginationNav,
    BSpinner,
    BFormCheckbox,
    BButton,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      checked: true,
      notchecked: false,
      optionsPerPage: [1, 3, 10, 25, 50, 100],
      selectedPerPage: 10,
      optionsSortBy: [
        {
          nama: 'Last Created',
          query: 'created_at|desc',
          id: 1,
        },
        {
          nama: 'Last Updated',
          query: 'updated_at|desc',
          id: 2,
        },
      ],
      selectedSortBy: {
        nama: 'Last Created',
        query: 'created_at|desc',
        id: 1,
      },
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'klien', label: 'Klien' },
        { label: 'Layanan Yang Dilaksanakan', key: 'deskripsi' },
        { label: 'Jumlah Waktu', key: 'jumlah_waktu' },
        { label: 'Bukti Pendukung', key: 'is_lampiran' },
        { label: 'Verifikasi', key: 'status_verifikasi' },
        {
          key: 'action',
          label: 'Action',
          tdClass: 'custom-cell-action',
        },
      ],
      daftarUser: [
        {
          id: '123',
          nama: 'Himawan Anindya Putra',
          email: 'saya@saya.com',
        },
      ],
      isBusy: false,
      selectedPageNum: 1,
      showPagination: false,
      pagination: {
        numberOfPages: 1,
        totalRows: 0,
        rowsPerPage: 0,
      },
      selectedNama: null,
      selectedKlien: null,
      selectedLayanan: null,
      currentQuery: null,
      currentQueryParam: null,
      laporan_id: null,
      role: null,
    }
  },
  async mounted() {
    emitter.on('VERIFICATION_UPDATED', e => {
      this.$router.go()
    })

    this.laporan_id = this.$route.params.id
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userBiodata = await api().get(`probono/biodatabyuser/${userData.id}`)
    this.role = userData.role
    this.biodata_id = userBiodata.data.biodata[0].id

    const queryParams = this.processQueryString(this.$route.query)

    this.selectedPerPage = this.$route.query.limit
      ? this.$route.query.limit
      : 10

    // const generatedQueryString = this.buildQueryString(queryParams)
    await this.executeBackend(queryParams)
    this.showPagination = true
  },
  async beforeRouteUpdate(to, from, next) {
    // console.log(to)
    const queryParams = this.processQueryString(to.query)
    // const generatedQueryString = this.buildQueryString(queryParams)
    this.executeBackend(queryParams)
    next()
  },
  methods: {
    async download(filename) {
      try {
        this.btnDownloadDisabled = true
        const result = await download().get(
          `probono/download/${this.laporan_id}/${filename}`,
        )
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        this.makeToast('danger', 'Error', 'File not found', true)
      }
    },
    openForm() {
      this.$router.push({
        name: 'probono-layanan-form',
        params: {
          id: this.$route.params.id,
          biodata_id: this.$route.params.biodata_id,
          aksi: 'tambah',
        },
      })
    },
    linkGen(pageNum) {
      const queryParams = this.processQueryString(this.currentQuery)
      queryParams.page = pageNum
      const generatedQueryString = this.buildQueryString(queryParams)
      // console.log(generatedQueryString)

      return `${generatedQueryString}`
      // console.log(pageNum)
    },
    newQueryParams() {
      return {
        laporan_id: null,
        biodata_id: null,
        role: null,
        nama: null,
        konten: null,
        sort_by: null,
        limit: 10,
        offset: null,
        page: 1,
      }
    },
    processQueryString(queryString) {
      const queryParams = this.newQueryParams()

      queryParams.biodata_id = this.biodata_id ? this.biodata_id : null

      queryParams.role = this.role ? this.role : null

      queryParams.laporan_id = this.laporan_id ? this.laporan_id : null

      const selectedLayanan = this.selectedLayanan ? this.selectedLayanan : null
      queryParams.layanan = queryString.layanan
        ? queryString.layanan
        : selectedLayanan

      const selectedKlien = this.selectedKlien ? this.selectedKlien : null
      queryParams.klien = queryString.klien ? queryString.klien : selectedKlien

      queryParams.sort_by = queryString.sort_by
        ? queryString.sort_by
        : this.selectedSortBy.query
      queryParams.limit = queryString.limit
        ? queryString.limit
        : this.selectedPerPage
      queryParams.offset = queryString.offset ? queryString.offset : 0
      queryParams.page = queryString.page ? queryString.page : 1

      return queryParams
    },
    buildQueryString(params) {
      const queryParams = params

      // disinilah kita merubah offset disesuaikan dengan param page
      const { page } = queryParams
      // hitung offset
      if (page) {
        if (page === '1') {
          queryParams.offset = 0
        } else {
          queryParams.offset = (page - 1) * queryParams.limit
        }
      }
      const keys = Object.keys(queryParams)
      let i = 0
      let currentQuery = ''
      keys.forEach(element => {
        if (
          queryParams[element] !== null &&
          queryParams[element] !== undefined
        ) {
          if (i === 0) {
            currentQuery += '?'
            i += 1
          } else {
            currentQuery += '&'
          }
          currentQuery += `${element}=${queryParams[element]}`
        }
      })
      return currentQuery
    },
    async executeBackend(queryParams) {
      const generatedQueryString = this.buildQueryString(queryParams)
      this.isBusy = true
      try {
        this.daftarUser.splice(0, this.daftarUser.length)
        const result = await api().get(
          `search/probono/layanan${generatedQueryString}`,
        )
        result.data.items.forEach(element => {
          const temp = element
          if (temp.probono_lampiran.length > 0) {
            temp.is_lampiran = true
          }
          this.daftarUser.push(temp)
        })
        this.pagination.totalRows = result.data.total
        this.pagination.numberOfPages = Math.ceil(
          this.pagination.totalRows / queryParams.limit,
        )
        if (this.pagination.numberOfPages === 0) {
          this.pagination.numberOfPages = 1
        }
        this.selectedLayanan = queryParams.layanan
        this.selectedKlien = queryParams.klien
        this.selectedPageNum = queryParams.page
        this.selectedSortBy = this.optionsSortBy.find(
          element => element.query === queryParams.sort_by,
        )
      } catch (error) {
        alert(error)
      }
      this.isBusy = false
      this.currentQuery = generatedQueryString
      this.currentQueryParam = queryParams
    },
    async filterSelected() {
      // reset query
      const queryParams = this.processQueryString({})
      const generatedQueryString = this.buildQueryString(queryParams)
      this.$router.push({
        path: `${generatedQueryString}`,
      })
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
    onEnterClick() {
      this.filterSelected()
    },
    tambahPenggunaBaru() {
      this.$router.push({
        name: 'support-add',
      })
    },
    verifikasi(index) {
      emitter.emit('VERIFIKASI', index)
    },
    confirmDelete(index) {
      this.$bvModal
        .msgBoxConfirm('Apakah anda yakin?', {
          cancelVariant: 'outline-secondary',
          okTitle: 'Hapus',
          cancelTitle: 'Batal',
          okVariant: 'danger',
        })
        .then(async value => {
          if (value) {
            try {
              this.isBusy = true
              const deleteLayanan = this.daftarUser[index]
              await api().delete(`probono/layanan/${deleteLayanan.id}`)
              this.daftarUser.splice(index, 1)
            } catch (error) {
              this.makeToast(
                'danger',
                'Error',
                error.response.data.message,
                true,
              )
              this.isBusy = false
              return
            }
            this.makeToast('success', 'Sukses', 'Data berhasil dihapus')
            this.isBusy = false
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.custom-cell-date {
  min-width: 200px;
  /* color: green;
  background-color: #00ff88; */
}
.custom-cell-action {
  min-width: 140px;
  /* color: green;
  background-color: #00ff88; */
}
</style>
